import React from "react"
import Layout from "../_components/Layout/Layout"
import MainTitle from "../_components/MainTitle/MainTitle"
import PolicyDescription from "../_components/PolicyDescription/PolicyDescription"

const TermsPoretti = () => {
    return (
        <Layout title="Terms & Conditions">
            <MainTitle className="rewards">Poretti Promotion Terms and Conditions</MainTitle>

            <div>
                <MainTitle secondary>Privacy Statement</MainTitle>
                <MainTitle secondary>Introduction</MainTitle>

                <PolicyDescription>
                    <p>This promotion is operated by Carlsberg Marston’s Brewing Company Limited (company number 00078439) with registered office at Marston’s House, Brewery Road, Wolverhampton WV1 4JT (<b>the Promoter</b>).</p>
                    <p>The Microsite (as defined in the Terms and Conditions) is developed and administered by Fabacus Ltd (company number 10086870) with registered office at 12 Soho Square, London, W1D 3QF (<b>Developer</b>).</p>
                    <p>The winner of this promotion will be drawn by the event organiser, International Management Group (UK) Limited (company number 00946580) with registered office at Building 6 Chiswick Park, 566 Chiswick High Road, London W4 5HR (<b>Event Organiser</b>).</p>
                    <p>The prizes available to be won in this promotion are arranged, fulfilled and delivered by Cloud Nine Incentives (company number 07342147) with registered office at NP=105, iCentre, Howard Way, Newport Pagnell, Milton Keynes, Buckinghamshire (<b>Fulfilment Agency</b>).</p>
                </PolicyDescription>

                <MainTitle secondary>How will your personal data be used?</MainTitle>

                <PolicyDescription>
                    <p>1. The personal data that you provide in connection with this promotion (and, if applicable, the personal data of your guest) will be used for the purposes of administering the promotion, verifying eligibility of entrants, selecting and contacting the winners and fulfilling the prize in accordance with the terms and conditions of this promotion below (“<b>Terms and Conditions</b>”).</p>
                    <ul>2. If you win the prize, the Developer/Event Organiser (as applicable) will:
                        <li><span>a.</span>make your name and county available, as a winner, to people requesting that information in accordance with the Terms and Conditions and as required by the UK Code of Non-broadcast Advertising and Direct & Promotional Marketing. If you do not want your name to be made available, please inform the Developer using the contact details in paragraph 9 of this Privacy Policy. Note that, if requested to do so, the Developer may still share winners’ details with the Advertising Standards Authority;</li>
                        <li><span>b.</span>share the personal data that you have provided in connection with this promotion (and, if applicable, the personal data of your guest) with the Fulfilment Agency to arrange fulfilment and/or delivery of your prize.</li>
                    </ul>
                    <p>3. If you win a prize (or you are a guest of a prize winner), then the Developer may ask you to sign a promotional release so that your name, county and photographs or other recordings of you and your guests may be used in connection with the prize in the Promoter's publicity concerning the promotion and its products, including on (or in) the Promoter's social media pages, websites, press releases and advertising.</p>
                    <p>4. The Terms and Conditions are the legal basis for the processing of personal data in connection with this promotion. If you do not provide your personal data, your entry cannot be processed, you will not be notified if you have won a prize, nor will it be possible to award a prize to you. If (where applicable) your guest does not provide their personal data, then they cannot be included in the prize.</p>
                    <p>5. Your personal data (and, if applicable, the personal data of your guest) will not be disclosed or shared with any third party other than those who are engaged to administer the promotion and to fulfil and deliver the prize(s), or third parties who request details of the winner(s) of this promotion as described at paragraph 2(a) above.</p>
                    <p>6. Your personal data (and, if applicable, the personal data of the winner’s guest) may be sent to countries outside of the UK in order to conduct aspects of the promotion (e.g. where fulfilment of the prize happens in another country). Where this occurs, the party responsible will ensure that any transfer happens in accordance with applicable law.</p>
                    <p>7. If you are unsuccessful in winning a prize, the Developer will delete your personal data promptly after the prizes have been awarded and distributed. If you are a winner, your personal data will not be processed for any longer than is required for the party processing your data to comply with the law or to fulfil its obligations to you and other third parties. To find out more, you can contact the relevant party using the contact details provided at paragraph 9 below.</p>
                    <p>8. In accordance with the applicable data protection laws and regulations, you are reminded that you (and your guest where applicable) may at any time exercise your rights of access, rectification, deletion, limitation of treatment, opposition to treatment (including a right to require that your personal data is erased), your right to amend your data and right to ask to port (transfer) your data directly to another third party. Please note that if you wish to exercise your right to require that your personal data is erased, this will automatically remove your entry from the promotion and, if you are a winner (or a guest of a winner), will mean that you cannot receive or take up the prize.</p>
                    <p>9. If you wish to exercise your data rights or wish to know more about how your data will be processed, you can contact the appropriate party or view that party’s privacy policy using the details set out below:</p>
                    <div>
                        <table className='terms-table'>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><b>Promoter:</b></p>
                                        <p>Carlsberg Marston’s Brewing Company Limited</p>
                                    </td>
                                    <td>
                                        <p><b>Email address:</b></p>
                                        <p><a href="mailto:privacy@carlsbergmarstons.co.uk">privacy@carlsbergmarstons.co.uk</a></p></td>
                                    <td>
                                        <p><b>Privacy Policy available at:</b></p>
                                        <p><a href="https://www.carlsbergmarstons.co.uk/privacy-notification/" target="_blank" rel="noreferrer">https://www.carlsbergmarstons.co.uk/privacy-notification/</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>Developer:</b></p>
                                        <p>Fabacus Ltd</p>
                                    </td>
                                    <td>
                                        <p><b>Email address:</b></p>
                                        <p><a href="mailto:taste@help.xela.co">taste@help.xela.co</a></p></td>
                                    <td>
                                        <p><b>Privacy Policy available at:</b></p>
                                        <p><a href="https://fabacus.com/privacy-policy/" target="_blank" rel="noreferrer">https://fabacus.com/privacy-policy/</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>Event Organiser:</b></p>
                                        <p>International Management Group (UK) Limited</p>
                                    </td>
                                    <td>
                                        <p><b>Email address:</b></p>
                                        <p><a href="mailto:dataprivacy@IMG.com">dataprivacy@IMG.com</a></p></td>
                                    <td>
                                        <p><b>Privacy Policy available at:</b></p>
                                        <p><a href="https://london.tastefestivals.com/privacy-policy/" target="_blank" rel="noreferrer">https://london.tastefestivals.com/privacy-policy/</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><b>Fulfilment Agency:</b></p>
                                        <p>Carlsberg Marston’s Brewing Company Limited</p>
                                    </td>
                                    <td>
                                        <p><b>Email address:</b></p>
                                        <p><a href="mailto:dataprotection@uponcloudnine.co.uk">dataprotection@uponcloudnine.co.uk</a></p></td>
                                    <td>
                                        <p><b>Privacy Policy available at:</b></p>
                                        <p><a href="https://www.uponcloudnine.co.uk/privacy.html" target="_blank" rel="noreferrer">https://www.uponcloudnine.co.uk/privacy.html</a></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>10. If you believe that your personal data is not being treated in accordance with the law, you have the right to file a complaint with the Information Commission’s Officer (ICO) or other competent authority. You can find more details about the ICO at their website <a href="https://ico.org.uk/" target="_blank" rel="noreferrer">https://ico.org.uk/</a>.</p>
                    <p>11. The Promoter will not collect or process any personal data in connection with this promotion.</p>
                </PolicyDescription>

                <MainTitle secondary>Terms and Conditions</MainTitle>

                <PolicyDescription>
                    <p>These Terms and Conditions apply to the "Lake Como Poretti Taste of London” promotion.  Please read these Terms and Conditions carefully as you will be legally bound from the time that you enter.</p>
                    <p>If there is a conflict in these Terms and Conditions with any other terms whatsoever attached to any other advertising material in respect of this promotion, these conditions will take precedence.</p>
                </PolicyDescription>

                <MainTitle secondary>Eligibility</MainTitle>

                <PolicyDescription>
                    <p>1. This promotion is open to all persons aged 18 or over and resident in England & Wales, other than employees, and their immediate families, of the Promoter, its associated companies and anyone else professionally connected with this promotion.</p>
                    <p>2. The Promoter reserves the right to verify the eligibility of entrants. The Promoter may require such information as it considers reasonably necessary for this purpose and a prize may be withheld until the Promoter is satisfied with the verification.</p>
                </PolicyDescription>

                <MainTitle secondary>How to Enter</MainTitle>

                <PolicyDescription>
                    <p>3. You will require internet access, an email address and a smartphone with a camera to enter this promotion. </p>
                    <ul>4. To enter the promotion you must, prior to the Closing Date:
                        <li><span>a.</span>scan the QR code printed on the promotional material displayed at the Poretti stand at the Taste of London, Regent’s Park, London, NW1 4NR or visit <a href="https://taste.xela.co" target="_blank" rel="noreferrer">https://taste.xela.co</a> (<b>Microsite</b>) and fully complete the online form, following the prompts on the Microsite including your name, email address, data of birth, ethnicity and your favourite cuisine.</li>
                    </ul>
                    <p>5. Any instructions provided at the point of entry form part of these Terms and Conditions. In the event of a conflict, these Terms and Conditions take precedence.</p>
                    <p>6. This promotion starts at 12:00 on 12 June 2024 and ends at 22:00 on 16 June 2024 (<b>Closing Date</b>). All entries must be received by the Closing Date. The Promoter accepts no responsibility for entries that are lost, delayed, corrupted or not received by the Closing Date for any reason. Proof of sending shall not be deemed to be proof of receipt.</p>
                    <p>7. Entrants may only enter this promotion using the Microsite. No more than 1 entry will be accepted per person for the duration of this Promotion. Entries not complying with these Terms and Conditions will be invalid.</p>
                </PolicyDescription>

                <MainTitle secondary>Prizes</MainTitle>

                <PolicyDescription>
                    <p>8. There is 1 prize available to be won in this promotion, consisting of, consisting of a three-night holiday for two people to Lake Como, Italy (<b>Prize</b>).</p>
                    <ul>9. The Prize includes:
                        <li><span>a.</span>return economy flights to Milan, Italy from one of the following airports operating direct flights to Milan: all London airports, Birmingham, Bristol, Edinburgh, Manchester and Newcastle airports (with ATOL/ABTA protection);</li>
                        <li><span>b.</span>one item of hold luggage to be shared by the winner and their guest and one item of under-seat hand luggage (handbag or rucksack) per person;</li>
                        <li><span>c.</span>return airport transfers at the destination;</li>
                        <li><span>d.</span>accommodation for three (3) nights at a 4* hotel, with the winner and their guest sharing a double or twin bedroom (or such equivalent accommodation as the Promoter may in its discretion provide) with breakfast included; </li>
                        <li><span>e.</span>food walking tour of Como;</li>
                        <li><span>f.</span>private boat trip on Lake Como; and</li>
                        <li><span>g.</span>dinner for two at a Michelin star restaurant (one night only).</li>
                    </ul>
                    <p>10. The Prize must be taken by the winner and their guest by no later than 30 June 2025 and may not be taken during the months of July or August 2024 or any dates that are statutory or public holidays in the United Kingdom or Italy. Travel dates are subject to booking availability and the winner acknowledges that their first preferred date may not be available.</p>
                    <p>11. The Prize will be booked by the Fulfilment Agency and the winner must provide all reasonable assistance to the Fulfilment Agency for such purposes. A valid credit or debit card may be required from the winner as a security deposit when checking into accommodation.</p>
                    <p>12. The winner and their guest must procure their own travel insurance and any necessary vaccinations, immunisations or similar and supply proof of adequate insurance to cover the trip to the Fulfilment Agency upon request. Both the winner and their guest must be legally able to travel to Italy on the date booked for travel (including, but not limited to, meeting any Covid-19 regulations / restrictions which apply at the time of travel) and obtain all necessary visas and other documentation for travel (including, but not limited to, any Covid-19 documentation required). Copies of valid passports will also be required.</p>
                    <p>13. It is the responsibility of the winners and their guests to ensure that their passport will remain valid for a sufficient amount of time to permit entry to Italy on the date of travel. If unsure of the requirements, the winner and their guest are advised to consult the guidance issued by HM Passport Office on the GOV.UK website.</p>
                    <p>14. Winners and their guests must be aged 18 years or older. The Fulfilment Agency reserves the right to verify the age of the winners and their guests by requesting copies of valid photo identification. If such identification shows that the winner is under 18 years old, the prize will be forfeited. If identification provided for a winner’s guest shows that the guest is under 18 years old, the winner will be asked to nominate a replacement guest who is aged 18 or older. Any failure by the winner to respond to requests for guest nomination or identification may result in the prize being forfeited.</p>
                    <p>15. In the event the Prize has not been redeemed by 30 June 2025, the winner’s entitlement to receive the Prize will expire. The Promoter accepts no responsibility for any failure or inability of the winner to redeem or take up the Prize by the date required.</p>
                    <p>16. Unless otherwise stated, any expenses incurred by the winner (and their guest, where applicable) in the general use and enjoyment of their prize are the sole responsibility of the winner (and their guest).</p>
                    <p>17. Any further Prize details will be confirmed when the winner is contacted. The Promoter reserves the right to substitute an alternative prize of at least equal value if circumstances make this necessary.</p>
                    <p>18. The Prize is non-transferable and no cash or other alternative will be offered. It is the responsibility of the winner to ensure that their prize can be used by them (and their guest if applicable).</p>
                </PolicyDescription>

                <MainTitle secondary>Winner Selection, Notification and Claiming</MainTitle>

                <PolicyDescription>
                    <p>19. The winner will be the first entry drawn at random from all eligible entries by a computer process that produces verifiably random results. The draw will take place on Monday 17 June 2024.</p>
                    <p>20. The winner will be notified they have won by the Developer within seven days of the Closing Date by direct message to the social media account from which the winning entry was received. Non-winning entrants will not be notified. The winner must confirm acceptance of the Prize and provide their email address and telephone number for further correspondence within 48 hours of win notification.</p>
                    <p>21. The winner will be contacted by the Fulfilment Agency within 14 days of the Closing Date to arrange fulfilment of the Prize. The Fulfilment Agency will make reasonable attempts to contact the winner using the email address and mobile telephone number provided by the winner. If the Fulfilment Agency has been unable to establish contact with the winner within 28 days of its first attempt, the Prize will be forfeited and a replacement winner drawn at the Promoter’s sole discretion.</p>
                    <ul>22. The Prize may be forfeited and a replacement winner drawn at the Promoter’s sole discretion in the following events:
                        <li><span>a.</span>the prize is declined, or a winner cannot take up their prize;</li>
                        <li><span>b.</span>a winner is disqualified under the Terms and Conditions of this promotion;</li>
                        <li><span>c.</span>a winner fails to provide their contact information to the Developer on request or the Fulfillment Agency is unable to establish contact with the winner within 28 days in accordance with paragraph 23; </li>
                        <li><span>d.</span>the winner and/or their guest are under 18;</li>
                        <li><span>e.</span>fulfillment of the Prize is refused or cannot be completed using reasonable attempts.</li>
                    </ul>
                    <p>23. The Promoter shall not be responsible for any inability of a winner (or their guest if applicable) to take up the Prize.</p>
                </PolicyDescription>

                <MainTitle secondary>General</MainTitle>

                <PolicyDescription>
                    <p>24. If you need to amend the contact details you provided to enter the promotion or wish to withdraw your entry, please email <a href="mailto:taste@help.xlea.co">taste@help.xlea.co</a>.</p>
                    <p>25. To obtain the name and county of the winner(s), please email <a href="mailto:taste@help.xlea.co">taste@help.xlea.co</a>. within three months of the Closing Date. Entrants can object to disclosure or request that disclosure be limited in scope by contacting the Promoter.  The Promoter may nevertheless disclose the information to the Advertising Standards Authority if required. </p>
                    <p>26. Insofar as is permitted by law, the Promoter, its associated companies and its agents will not in any circumstances be responsible or liable to compensate any winner (or any guest of a winner) or accept any liability for any loss, damage, personal injury or death occurring as a result of taking up the prize except where it is caused by the negligence of the Promoter, its agents or their employees. Your statutory rights are not affected.</p>
                    <p>27. The Promoter accepts no responsibility or liability for (a) any entries or winner notifications which are not received due to the use of incorrect or inaccurate entry information, any faulty or failed electronic data transmissions, or for any other reason; (b) any unauthorised access to, or theft, destruction or alteration of entries at any point in the operation of the promotion; (c) any technical malfunction, failure, error, omission, interruption, deletion, defect, delay in operation or communications line failure, regardless of cause, with regard to any equipment, systems, networks, lines, satellites, servers, computers or providers used in any aspect of the operation of the promotion; (d) inaccessibility or unavailability of any network or wireless service, the Internet and/or any website; or (e) any injury or damage to any person’s computer(s) or mobile device(s) which may be related to or result from any attempt to participate in the promotion or to download any materials in connection with the promotion.</p>
                    <p>28. The Promoter reserves the right to suspend, cancel or amend the promotion and / or revise these Terms and Conditions at any time without giving prior notice and, by continuing to take part in the promotion subsequent to any revision, entrants shall be deemed to have agreed to the amended terms.</p>
                    <p>29. The Promoter may in its sole discretion disqualify entries deemed to be non-compliant with these Terms and Conditions or if it has any reason to believe that efforts have been made to distort the competitive process or spirit of the promotion. This includes any individual found to be attempting to circumvent this clause by tampering with the operation of the promotion, setting up multiple accounts or using multiple identities. No bulk, consumer group or third party entries will be accepted. The Promoter’s decision as to any aspect of this promotion is final. No correspondence will be entered into.</p>
                    <p>30. By entering this promotion, each entrant warrants that all information submitted about them is true, current and complete.</p>
                    <p>31. Entry into this promotion is deemed acceptance of these Terms and Conditions.</p>
                    <p>32. If any provision of these terms and conditions is held invalid, such invalidity shall not affect the enforceability of any other provisions not held to be invalid.</p>
                    <p>33. This promotion and all issues arising out of it shall be governed in accordance with English law and is subject to the exclusive jurisdiction of the English courts.</p>
                    <p><b>Promoter</b>: Carlsberg Marston’s Brewing Company Limited, Marston's House, Brewery Road, Wolverhampton, England, WV1 4JT</p>
                    <p><b>Please drink responsibly. Bedrinkaware.co.uk.</b></p>
                </PolicyDescription>

                <MainTitle secondary>Summary Terms and Conditions</MainTitle>

                <PolicyDescription>
                    <p>18+ E&W only. Internet, email, mobile number & smartphone with camera required. Starts 12:00 12.06.24. Ends 22:00 16.06.24. Scan QR code printed on the promotional material displayed at the Poretti stand at the Taste of London, Regent’s Park, London, NW1 4NR or visit <a href="https://taste.xela.co" target="_blank" rel="noreferrer">https://taste.xela.co</a> & fully complete entry form for chance to win. Prize: 1 x 3 night-trip to Lake Como, Italy for x2 (must both be over 18). Inc. return direct economy flights to Milan, Italy from any London, Birmingham, Bristol, Edinburgh, Manchester or Newcastle airport, 4* hotel with breakfast, 1 hold luggage to be shared & activities including walking tour, boat trip and dinner for x2, x1 night only*. Winners drawn at random on 17.06.24. Max. 1 entry per person. Max. For full T&Cs & privacy policy visit <a href="https://taste.testing.xela.co/terms/" target="_blank" rel="noreferrer">https://taste.testing.xela.co/terms/</a>. <b>Promoter</b>: Carlsberg Marston’s Brewing Company Limited, Marston's House, Brewery Road, Wolverhampton, England, WV1 4JT. <b>Please drink responsibly. Bedrinkaware.co.uk.</b></p>
                </PolicyDescription>
            </div>
        </Layout >
    )
}

export default TermsPoretti
